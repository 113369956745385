import React from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const children = dataSource.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item}>
            {item.children}
          </Button>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    const cardList = dataSource.cardWrapper.children.map((item) => {
      const { tag, tagBg, tagColor, title, pic, picBg, href  } = item;
      return (
        <div className='card-content'>
          <a href={href} target="_blank" rel="noreferrer">
            <div className='card-pic' style={{ backgroundColor: picBg }}>
              <img src={pic} alt={tag} />
            </div>
            <div className='card-tag' style={{ color: tagColor, backgroundColor: tagBg }}>{tag}</div>
            <div className='card-title'>{title}</div>
          </a>
        </div>
      );
    });
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          className='queue-admin'
        >
          <div {...dataSource.textWrapper}>
            {children}
          </div>
          <div {...dataSource.cardWrapper}>
            {cardList}
          </div>
        </QueueAnim>
        <div className='hot-box'>
          <div className='hot-content'>
            <span className='hot-title'>{dataSource.cardWrapper.hotPlan.title}</span>
            <Button type="primary">
              <a href={dataSource.cardWrapper.hotPlan.href}
                target='_blank' rel="noreferrer">{dataSource.cardWrapper.hotPlan.more}</a>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default Banner;
