import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://www.chengwenit.com/img/logo/jilogo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#',
          children: [{ children: '帮助中心', name: 'text' }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: 'https://doc.chengwenit.com',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/appcenter.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '应用中心',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '成集云支持哪些应用？',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: 'https://doc.chengwenit.com',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/casecenter.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '方案中心',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '支持哪些集成方案？',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item4',
        className: 'header3-item',
        children: {
          href: '#',
          children: [{ children: '产品介绍', name: 'text' }],
        },
        subItem: [
          {
            name: 'sub0',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: '#',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/jibzb.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '标准版',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '通用行业数据集成版本',
                },
              ],
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              href: 'https://yao.chengwenit.com',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children:
                    'https://www.chengwenit.com/img/icon/jiyao.svg',
                },
                {
                  name: 'title',
                  className: 'item-title',
                  children: '医药行业版',
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: '数据集成、计算、服务全链产品',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#Pricing0_0',
          children: [{ children: '价格', name: 'text' }],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: 'https://www.chengwenit.com/#Content1_0',
          children: [{ children: '关于我们', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      // {
      //   name: 'nameEn',
      //   className: 'banner3-name-en',
      //   children: '探索企业内外协同全链数字化最佳实践',
      // },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '安全稳定高效异构系统集成',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name',
        children: '可视化集成ERP/CRM/WMS/SRM/OA/MES/三方支付/电子发票/电商平台等应用,实现全链数字化',
      },
      {
        name: 'button',
        className: 'banner3-button',
        children: (
          <span>
            <p> <a href='https://p.qiao.baidu.com/cps/chat?siteId=18658173&userId=44582102&siteToken=dda4443ca14bc4f464d2c5ed810b7a30&cp=%E6%88%90%E9%9B%86%E4%BA%91%E5%AE%98%E7%BD%91&cr=&cw=banaer%E4%B8%AD%E9%97%B4' >联系我们</a></p>
          </span>
        ),
      },
     // {
     //   name: 'time',
     //   className: 'banner3-time',
     //   children: '成文数科',
     // },
    ],
  },
  cardWrapper: {
    className: 'banner3-card-wrapper',
    children: [
      {
        tag: 'OMS集成ERP',
        tagBg: '#d6e2ff',
        tagColor: '#203d7e',
        title: '聚水潭客户、订单、物流单据同步至金蝶ERP',
        pic: 'https://www.chengwenit.com/img/jiimg/jst2kd.png',
        picBg: '#fff3dc',
        href: 'https://doc.chengwenit.com/cases'
      }, {
        tag: 'ERP集成OA',
        tagBg: '#fddbda',
        tagColor: '#72302d',
        title: '鼎捷ERP集成蓝凌EKP单据审批流程',
        pic: 'https://www.chengwenit.com/img/jiimg/dj2ll.png',
        picBg: '#e5f1ff',
        href: 'https://doc.chengwenit.com/cases'
      }, {
        tag: '费控集成财务',
        tagBg: '#d6e2ff',
        tagColor: '#203d7e',
        title: '钉钉费用报销写入用友财务凭证',
        pic: 'https://www.chengwenit.com/img/jiimg/dd2yy.png',
        picBg: '#e6f6ff',
        href: 'https://doc.chengwenit.com/cases'
      }, {
        tag: 'CRM集成EHR',
        tagBg: '#fddbda',
        tagColor: '#72302d',
        title: '纷享销客CRM考勤和签到写入金蝶SHR cloud',
        pic: 'https://www.chengwenit.com/img/jiimg/fx2shr.png',
        picBg: '#e6f6ea',
        href: 'https://doc.chengwenit.com/cases'
      }
    ],
    hotPlan: {
      title: '热门方案开箱即用',
      more: '查看更多',
      href: 'https://doc.chengwenit.com'
    }
  }
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '成集云如何工作？',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '支持公有云saas,混合云,私有云三种部署方式',
      },
    ],
  },
  img: {
    children: 'https://www.chengwenit.com/img/prod/prod_cjy.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://www.chengwenit.com/img/icon/sjjc.svg',
          className: 'content6-icon',
        },
        title: { className: 'content6-title', children: '数据集成' },
        content: {
          className: 'content6-content',
          children:
            '丰富的对接方式,专属安全通道,支持700+应用集成。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://www.chengwenit.com/img/icon/rwdd.svg',
        },
        title: { className: 'content6-title', children: '任务调度' },
        content: {
          className: 'content6-content',
          children:
            '新一代分布式任务调度平台,多种定时方式,失败重传预警,任务工作流编排,全程可控',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://www.chengwenit.com/img/icon/sjfw.svg',
        },
        title: { className: 'content6-title', children: '数据服务平台' },
        content: {
          className: 'content6-content',
          children:
            '主数据管理,数据质量管理,元数据管理,数据血缘分析,多端数据可视化',
        },
      },
    ],
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '快速构建稳定安全的应用集成',
        className: 'title-h1',
      },
      { name: 'content', children: '基于成集云敏捷的系统集成接口' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>数据集成平台</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 10,
            xs: 24,
            children: (
              <span>
                <h2>接口丰富</h2>
                <p>
                  支持数据库直连/restful webapi/kafka/mq消息队列/FTP/S3文件传输等多样化的数据集成接口
                </p>
                <br />
                <h2>广泛应用</h2>
                <p>
                  支持市面上主流ERP/WMS/CRM/SRM/OA/开放银行/电商平台/第三方支付/电子合同/云服务应用集成
                </p>
                <br />
                <h2 normal>
                  海量方案
                </h2>海量的集成方案模板复用,专业标准的实施方法论,保障交付成功,持续稳定运维
 </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://www.chengwenit.com/img/prod/jinei.png',
            md: 14,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: {
            className: 'content7-tag-name',
            children: (
              <span>
                <span>
                  <p>任务调度平台</p>
                </span>
              </span>
            ),
          },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 10,
            xs: 24,
            children: (
              <span>
                <h2>分布式执行</h2>
                <p>
                集中调度,分布式执行,支持CRON/固定频率/固定延迟等定时调度策略,工作流来编排任务解决依赖关系,大数据任务分片
                </p>
                <br />
                <h2>消息推送</h2>
                <p>
                支持短信/钉钉/企业微信/公众号/邮件等渠道推送任务失败通知/巡检通知/数据报表
                </p>
                <br />
                <h2>安全备份</h2>
                <p>
                支持数据库独写分离检测任务执行，平台级独写分离性能无忧
                </p>
                <br />
                <h2>质量检测</h2>
                <p>
                内置主数据质量清洗规则，避免脏数据/重复数据/错误数据
                </p>

              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 14,
            xs: 24,
            children:
              'https://www.chengwenit.com/img/prod/prod-cjy1.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>全链数字化</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h2>企业上下游</h2>
                <p>
                  通过成集云平台，构筑SRM/CRM集成企业上下游供应商、生产厂商、经销商、终端客户，构建全链数字化
                </p>
                <br />
                <h2>外部机构</h2>
                <p>
                  集成开放银行、电子税务、电子签名、物流快递服务商、广告商、第三方支付，落地数字化的最后一个公里
                </p>
                <br />
                <h2>
                  云服务
                </h2>集成公有云服务短信推送、数据仓库归档、大数据计算、OCR识别、语音识别、智能客服强化企业数字化能力
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://www.chengwenit.com/img/prod/jiwai.png',
          },
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <span>
              <p>产品指标</p>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+' },
              toText: true,
              children: '706',
            },
            children: { className: 'feature6-text', children: '支持应用' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+' },
              toText: true,
              children: '7000',
            },
            children: { className: 'feature6-text', children: '方案模板' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '+' },
              toText: true,
              children: '100',
            },
            children: { className: 'feature6-text', children: '应用案例' },
          },
        ],
      },
      {
        title: { className: 'feature6-title-text', children: '平台指标' },
        className: 'feature6-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '个' },
              toText: true,
              children: '116',
            },
            children: { className: 'feature6-text', children: '执行器数量' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '万' },
              toText: true,
              children: '1.17',
            },
            children: { className: 'feature6-text', children: '运行任务数量' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '7.11',
            },
            children: { className: 'feature6-text', children: '累计调度次数' },
          },
        ],
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      'https://www.chengwenit.com/img/prod/prod0.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: '数据集成平台SaaS版',
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children:
          '高可用容灾，严苛的安全体系，全方位保障应用及数据安全。</br>流程执行次数:10000次/每月、国内流量:10G/月、归档日志存储3个月、归协助搭建任务：1个，专属在线客服7*8小时服务。',
        className: 'pricing0-content',
      },
      { name: 'pricing', children: '¥1,000/年', className: 'pricing0-pricing' },
      {
        name: 'button',
        children: {
          icon: '',
          href: 'https://p.qiao.baidu.com/cps/chat?siteId=18658173&userId=44582102&siteToken=dda4443ca14bc4f464d2c5ed810b7a30&cp=%E6%88%90%E9%9B%86%E4%BA%91%E5%AE%98%E7%BD%91&cr=&cw=%E4%BB%B7%E6%A0%BC%E5%92%A8%E8%AF%A2%E6%8C%89%E9%92%AE',
          type: 'primary',
          children: '立即咨询',
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1',
        children: (
          <span>
            <p>交付服务流程</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://wj.qq.com/s2/10673061/ecc3/',
          children: '发起需求',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: (
              <span>
                <span>
                  <span>
                    <p>数据集成流程</p>
                  </span>
                </span>
              </span>
            ),
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/diaoyan.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>需求调研</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '专业数据集成业务顾问，调研和分析需求，分析可行性',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/lantu.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>接口设计</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '接收需求，转化为设计方案，明确对接的流程/字段/清洗规则/调度策略',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/shishi.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>实施开发</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '配置接口、链条测试、部署上线',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/yunwei.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>上线维护</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '上线后支持，保障成功应用、知识转移',
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>运维服务流程</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/faqi.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>服务工单发起</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '由用户或者成文实施顾问发起服务请求',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/yixian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>一线客服</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '一线客服人员服务台接单，处理基本操作和咨询问题',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/erxian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>二线技术支持</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '一线客服解决不了技术问题，如系统配置、运行环境故障排查等，有技术支持人员排查和处理',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://www.chengwenit.com/img/service/jiantou.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://www.chengwenit.com/img/service/sanxian.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>三线研发</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '技术支持无法解决的程序BUG，性能调优、架构建议有研发人员进行支持',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '推荐' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'https://cloud.chengwenit.com',
              children: (
                <span>
                  <span>
                    <p>企业上云</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: 'http://ji.chengwenit.com',
              children: (
                <span>
                  <span>
                    <span>
                      <p>调度平台</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link2',
              href: 'http://ji.chengwenit.com',
              children: (
                <span>
                  <p>数据集成</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: 'https://mp.weixin.qq.com/s?__biz=MzU4Nzc1ODMxOQ==&mid=2247484143&idx=1&sn=03fad7c1c0d415a47c62a5821a41348f&chksm=fde66d6fca91e47970a26c6aa8f90e6bd3cffc902248a739e96d7e8dfeaa6020b9156ff0ca5c#rd',
              children: (
                <span>
                  <p>
                    <span>数据安全</span>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kj8d6orbk2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <p>联系我们</p>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'https://p.qiao.baidu.com/cps/chat?siteId=18658173&userId=44582102&siteToken=dda4443ca14bc4f464d2c5ed810b7a30&cp=%E6%88%90%E9%9B%86%E4%BA%91%E5%AE%98%E7%BD%91&cr=&cw=%E5%BA%95%E9%83%A8%E5%9C%A8%E7%BA%BF%E5%AE%A2%E6%9C%8D',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>在线客服</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link1',
              href: 'https://p.qiao.baidu.com/cps/chat?siteId=18658173&userId=44582102&siteToken=dda4443ca14bc4f464d2c5ed810b7a30&cp=%E6%88%90%E9%9B%86%E4%BA%91%E5%AE%98%E7%BD%91&cr=&cw=%E5%BA%95%E9%83%A8%E7%94%B5%E8%AF%9D',
              children: (
                <span>
                  <span>
                    <p>Tel:&nbsp; 18617332875</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link2',
              href: 'https://p.qiao.baidu.com/cps/chat?siteId=18658173&userId=44582102&siteToken=dda4443ca14bc4f464d2c5ed810b7a30&cp=%E6%88%90%E9%9B%86%E4%BA%91%E5%AE%98%E7%BD%91&cr=&cw=%E5%BA%95%E9%83%A8%E5%BE%AE%E4%BF%A1',
              children: (
                <span>
                  <span>
                    <p>Wechat:&nbsp; xiangyun_it</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'link3',
              href: 'https://blog.chengwenit.com',
              children: (
                <span>
                  <p>Blog</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: 'https://www.chengwenit.com/img/logo/gzhm.jpg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>成文科技公众号</p>
                </span>
              ),
              className: 'kj8e0fiwri-editor_css',
            },
          ],
        },
      },
      {
        name: 'block~kj8d34w57fd',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: 'https://www.chengwenit.com/img/logo/wxkf.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>成文科技在线客服</p>
                </span>
              ),
              className: 'kj8e0mn7thk-editor_css',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              Copyright © 广州成文数字科技有限公司 | 2023  &nbsp; &nbsp;
              <a href="http://beian.miit.gov.cn">粤ICP备17077704号</a> <br />
            </span>
          </span>
        </span>
      </span>
    ),
  },
}